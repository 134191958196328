﻿import { StatusCodes } from "http-status-codes";
import { get, initForm } from "@/Utils";

function initResetPasswordForm() {
  const callback = (resp: Response) => resp.text().then(text => {
      get("reset-password").innerHTML = text;
      initView();
  })
  initForm("reset-password-form", callback);
}

function initChangePasswordForm() {
  const callback = (response: Response) => {
      if (response.status === StatusCodes.IM_A_TEAPOT) {
          return response.text().then(text => {
              if (text) {
                  window.location.href = text;
              }
          });
      }
      else {
          return response.text().then(text => {
              get("change-password").innerHTML = text;
              initView();
          });
      }
  }
  initForm("change-password-form", callback);
}

function initView() {
  initResetPasswordForm();
  initChangePasswordForm();
}

window.addEventListener("load", () => {
  initView();
});